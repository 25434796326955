import {
  AddressMismatchReason,
  DisplayableOrderItem,
  getDisplayablePrice,
  validateAddress,
  validateAddressForLocations,
  ValidateAddressReason,
} from '@wix/restaurants-client-logic';
import { TranslationFunction } from 'i18next';
import { Address } from '@wix/restaurants-client-logic/dist/types/types/Address';
import { DispatchInfo, Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { PartialLocation } from '../../../../core/oloApi';
import { Code, EstimateDeliveryRequest, OrderError } from '@wix/ambassador-restaurants-local-delivery/types';
import { Modals } from '../../../../core/constants';
import { SetIsFetchingEstimateFromTPAPayload } from '../../../../state/checkout/checkout.actions.types';
import { RestaurantsLocalDelivery } from '@wix/ambassador-restaurants-local-delivery/http';
import _ from 'lodash';
import Experiments from '@wix/wix-experiments';

export function getErrorKey(orderItem: DisplayableOrderItem) {
  if (orderItem.isDishDeleted) {
    return 'cart_issues_error_hidden';
  }

  const error = orderItem.errors[0];

  switch (error?.type) {
    case 'order_delivery_type':
      return 'cart_issues_error_delivery_method_unavailable';
    case 'order_delivery_time':
      return error.reason === 'soldout' ? 'cart_issues_error_out_of_stock' : 'cart_issues_error_delivery_time_change';
    case 'order_platform':
      return 'cart_issues_error_unavailable_platform';
    default:
      return undefined;
  }
}

export function getValidateAddressReasonText(
  currency: string,
  locale: string,
  t: TranslationFunction,
  addressInputValue: string,
  reason?: ValidateAddressReason,
) {
  if (reason?.type === 'out-of-bounds') {
    return t('checkout_main_delivery_address_errormessage');
  } else if (reason?.type === 'unavailable') {
    return t('checkout_main_address_unavailable_errormessage');
  } else if (reason?.type === 'minimum-price') {
    return t('checkout_main_delivery_minimumprice_errormessage', {
      price: getDisplayablePrice(reason.minPrice, locale, currency),
    });
  } else if (reason?.type === 'pinpoint-error') {
    return t('checkout_main_buildingnumber_errormessage');
  } else if (reason?.type === 'invalid-address') {
    return addressInputValue === '' ? t('checkout_main_address_mandatory') : t('checkout_main_address_invalid');
  } else if (reason?.type === 'invalid-dropoff-address') {
    return t('checkout_deliveryintegration_errormsg_invaliddropoff_text');
  } else if (reason?.type === 'dropoff-address-not-serviceable') {
    return t('checkout_deliveryintegration_errormsg_dropoffserviceable_text', { partnername: 'Our partner' });
  }
}

interface GetDisplayableAddressErrorArgs {
  address: Address;
  restaurant: Restaurant;
  totalOrderPrice?: number;
  dispatchTime?: number;
  t: TranslationFunction;
  isAptRequired?: boolean;
  isMultiLocation?: boolean;
  locations?: PartialLocation[];
}

function isAddressAptValid(address: Address) {
  return Boolean(address.apt || address.addressLine2);
}

export interface FetchEstimateDeliveryFeeProps {
  addressToValidate: Address;
  configurationId: string;
  currency: string;
  subtotal: number;
  idealDeliveryArea?: DispatchInfo;
  signedInstance?: string;
  localDispatchTime?: number;
  setIsFetchingEstimateFromTPA: (payload: SetIsFetchingEstimateFromTPAPayload) => void;
  experiments: Experiments;
  hasOnlinePayment: boolean;
  biLogger: any;
  stage: string;
  locationGuid?: string;
}

export async function fetchEstimateDeliveryFee({
  addressToValidate,
  idealDeliveryArea,
  configurationId,
  subtotal,
  currency,
  setIsFetchingEstimateFromTPA,
  signedInstance,
  localDispatchTime,
  experiments,
  hasOnlinePayment,
  biLogger,
  stage,
  locationGuid,
}: FetchEstimateDeliveryFeeProps) {
  const result: {
    fee?: number;
    estimateId?: string;
    configurationId?: string;
    errors?: OrderError[];
  } = {
    fee: undefined,
    estimateId: undefined,
    errors: undefined,
  };
  if (experiments.enabled('specs.restaurants.disable-delivery-partner-orders') || !hasOnlinePayment) {
    return { errors: [{ code: Code.DROPOFF_ADDRESS_NOT_SERVICEABLE }] };
  }
  const subdividion = addressToValidate.properties
    ? JSON.parse(addressToValidate.properties['com.wix.restaurants']).subdivision
    : 'NY';
  const estimateRequest: EstimateDeliveryRequest = {
    configurationId,
    dropoffAddress: {
      subdivision: subdividion,
      addressLine2: addressToValidate.addressLine2,
      apt: addressToValidate.apt,
      city: addressToValidate.city,
      countryCode: addressToValidate.countryCode,
      entrance: addressToValidate.entrance,
      floor: addressToValidate.floor,
      location: { latitude: addressToValidate.latLng.lat, longitude: addressToValidate.latLng.lng },
      street: addressToValidate.street,
      streetNumber: addressToValidate.number,
      zipCode: addressToValidate.postalCode,
    },
    orderTotal: {
      currency,
      value: (subtotal / 100).toString(),
    },
    dropoffTime: getDropoffTimeForDeliveryPartner({
      dispatchInfo: idealDeliveryArea!,
      futureOrderTime: localDispatchTime,
    }),
  };
  const headers = { Authorization: signedInstance };

  try {
    setIsFetchingEstimateFromTPA({ isFetching: true });
    const estimate = await RestaurantsLocalDelivery('/restaurants')
      .RestaurantsLocalDelivery()(headers)
      .estimateDelivery(estimateRequest);

    const fee: string = estimate && estimate.fee ? estimate.fee.value! : '';
    result.estimateId = estimate.id;
    result.fee = Number.parseFloat(fee) * 100;
  } catch (e) {
    const isServerError = _.get(e, 'response.details.validationError') !== undefined;
    const rawError = _.get(
      e,
      'response.message',
      JSON.stringify({ errors: [{ code: Code.INVALID_WEIGHT_OR_VOLUME }] }),
    );
    result.errors = !isServerError ? JSON.parse(rawError).errors : [{ code: Code.INVALID_WEIGHT_OR_VOLUME }];
  } finally {
    setIsFetchingEstimateFromTPA({ isFetching: false });
    biLogger.liveSiteDeliveryPartnerEstimations({
      configurationId,
      stage,
      integrationAppId: 'unknown',
      integrationName: 'unknown',
      status: result.fee !== undefined,
      currency,
      deliveryFee: result.fee,
      errorDescription: result.errors,
      estimateId: result.estimateId,
      locationGuid,
    });
  }
  return result;
}

export function getDisplayableAddressError({
  address,
  restaurant,
  dispatchTime,
  totalOrderPrice,
  t,
  isAptRequired,
  isMultiLocation,
  locations,
}: GetDisplayableAddressErrorArgs) {
  let validateAddressReason;
  if (isMultiLocation && address) {
    const partnerProps = //  temporary solution for SL new flow to work with delivery partner
      locations && locations.length < 2
        ? { shouldConsiderDeliveryPartner: true, deliveryPartnerFee: undefined }
        : undefined;
    const { hasLocations, locations: validLocations, reason } = validateAddressForLocations(
      locations || [],
      address,
      partnerProps,
    );
    if (hasLocations && validLocations) {
      return;
    }
    if (reason) {
      validateAddressReason = reason;
    }
  } else {
    validateAddressReason = validateAddress({
      address,
      restaurant,
      dispatchTime,
      totalOrderPrice,
      deliveryPartnerProps: {
        shouldConsiderDeliveryPartner: true,
      },
    });
  }
  const invalidAptText =
    isAptRequired && !isAddressAptValid(address)
      ? t('checkout_main_delivery_contactinfo_mandatoryfield_errormessage')
      : undefined;
  return (
    getValidateAddressReasonText(restaurant.currency, restaurant.locale, t, address.formatted, validateAddressReason) ||
    invalidAptText
  );
}

export interface EstimateError {
  addressError?: AddressMismatchReason;
  modalError?: Modals;
  inlineError?: InlineError;
}

export type InlineError = 'time' | 'missing-time';

export function resolveEstimateRequestError(
  t: TranslationFunction,
  partnerName: string,
  viewedFrom: 'multi-location-modal' | 'checkout',
  error?: OrderError,
): EstimateError {
  if (!error) {
    return {
      addressError: 'unavailable',
    };
  }
  switch (error.code) {
    case Code.MISSING_PICKUP_OR_DELIVERY_TIME:
      return {
        inlineError: 'missing-time',
      };
    case Code.INVALID_DROPOFF_ADDRESS:
      return {
        addressError: 'invalid-dropoff-address',
      };
    case Code.DROPOFF_ADDRESS_NOT_SERVICEABLE:
    case Code.UNAVAILABLE_PICKUP_OR_DELIVERY_TIME:
    case Code.INVALID_PRICE:
    case Code.INVALID_WEIGHT_OR_VOLUME:
    case Code.INVALID_TIP:
    case Code.INVALID_PHONE_NUMBER:
    case Code.INVALID_ORDER_TOTAL:
    case Code.INVALID_PICKUP_ADDRESS:
    case Code.ALCOHOL_NOT_PERMITTED:
    case Code.CASH_UNAVAILABLE:
    case Code.INELIGIBLE_FOR_CASH:
    case Code.EMPTY_ORDER:
    case Code.SYSTEM_ERROR:
    case Code.PICKUP_ADDRESS_NOT_SERVICEABLE:
    case Code.CONTACTLESS_UNAVAILABLE:
    default:
      return {
        inlineError: 'time',
      };
  }
}

export function calcDropoffTime({
  dispatchInfo,
  futureOrderTime,
}: {
  dispatchInfo?: DispatchInfo;
  futureOrderTime?: number;
}) {
  const TimeNowPlusTwentyMinInMS = Date.now() + 60 * 20 * 1000;
  const delayMins = dispatchInfo?.delayMins || 0;
  let dropoffTime = futureOrderTime || Date.now() + 60 * delayMins * 1000;

  if (dropoffTime < TimeNowPlusTwentyMinInMS) {
    dropoffTime = TimeNowPlusTwentyMinInMS;
  }

  return dropoffTime;
}

export function getDropoffTimeForDeliveryPartner({
  dispatchInfo,
  futureOrderTime,
}: {
  dispatchInfo?: DispatchInfo;
  futureOrderTime?: number;
}) {
  const dropoffTime = calcDropoffTime({ dispatchInfo, futureOrderTime });

  return new Date(dropoffTime);
}

export function getEstimateErrorText({
  tpaEstimateInlineError,
  t,
}: {
  tpaEstimateInlineError?: string;
  t: TranslationFunction;
}) {
  switch (tpaEstimateInlineError) {
    case 'missing-time':
      return t('checkout_deliveryintegration_errormsg_validtime_text');
    case 'time':
      return t('checkout_deliveryintegration_errormsg_systemerror_text');
    default:
      t('checkout_deliveryintegration_errormsg_invalidpickup_text');
  }
}
