import React from 'react';
import styles from './ModalLayoutEditor.scss';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import classNames from 'classnames';

export interface ModalLayoutEditorProps {
  illustration: React.ReactNode;
  header: string | React.ReactNode;
  description: string | React.ReactNode;
  cta?: string;
  onCtaClick?: () => void;
  footer?: React.ReactNode;
  onCloseClick: () => void;
  'data-hook'?: string;
  className?: string;
  theme: 'regular' | 'premium';
}

const ModalLayoutEditor: React.FC<ModalLayoutEditorProps> = ({
  illustration,
  header,
  description,
  cta,
  onCtaClick,
  onCloseClick,
  footer,
  className,
  theme,
  ...rest
}) => {
  return (
    <div className={classNames([styles.wrapper, className, styles[theme]])} data-hook={rest['data-hook']}>
      <button className={styles.close} onClick={() => onCloseClick()}>
        <Close fill="#FFF" width={16} height={16} />
      </button>

      <div className={styles.topBlock} />

      <div className={styles.svgWrapper}>{illustration}</div>

      <section className={styles.innerWrapper}>
        <h2 className={styles.header}>{header}</h2>

        <p className={styles.text}>{description}</p>

        <button className={styles.cta} onClick={onCtaClick}>
          {cta}
        </button>
      </section>
      <footer className={styles.footer}>{footer}</footer>
    </div>
  );
};

ModalLayoutEditor.displayName = 'ModalLayoutEditor';

export default ModalLayoutEditor;
