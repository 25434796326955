import Text, { TextProps as TProps } from './Text';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';

function mapStateToProps(state: AppState, ownProps: TextProps) {
  return {
    ...ownProps,
  };
}

export default connect(mapStateToProps)(Text);

export type TextProps = TProps;
