import React from 'react';
import { useEnvironment } from 'yoshi-flow-editor-runtime';
import NavigationHeader from '../NavigationHeader';
import Sticky from '../Sticky/Sticky';
import styles from './StickyNavigationHeader.scss';

export interface StickyNavigationHeaderProps {}

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isEditor: false };
  }
}

const StickyNavigationHeader: React.FC<StickyNavigationHeaderProps> = () => {
  const [showButtonPlaceholder, toggleShowButtonPlaceholder] = React.useState(false);
  const { isEditor } = useEnvironmentSafe();

  return isEditor ? (
    <NavigationHeader />
  ) : (
    <Sticky className={styles.wrapper} onChange={(isSticky) => toggleShowButtonPlaceholder(isSticky)}>
      <NavigationHeader showButtonPlaceholder={showButtonPlaceholder} />
    </Sticky>
  );
};

StickyNavigationHeader.displayName = 'StickyNavigationHeader';

export default StickyNavigationHeader;
