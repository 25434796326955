import React, { useRef, useLayoutEffect } from 'react';
import ReactModal, { Aria, Props } from 'react-modal';
import styles from './Modal.scss';
import classNames from 'classnames';

export type ModalProps = Pick<Props, 'isOpen' | 'onRequestClose' | 'shouldCloseOnOverlayClick' | 'shouldCloseOnEsc'> & {
  children: React.ReactNode | React.ReactNode[];
  isSideContent?: boolean;
  hasInnerScroll?: boolean;
  aria?: Aria;
  isRTL?: boolean;
  isMobile?: boolean;
  compClassName: string;
};

let parentSelector: () => HTMLElement = () => document.body;

const Modal: React.FC<ModalProps> & {
  setAppElementSelectors: (modalSelector: string, mainSelector: string, isEditorX: boolean) => void;
} = (props) => {
  const { isSideContent, isRTL, isMobile } = props;
  const ref = useRef<ReactModal>(null);
  const [wasOpen, setWasOpen] = React.useState(false);

  function afterOpenModal() {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  }

  function afterClose() {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  }
  // Slight hack for resolving issue with focus being removed from modal if the focused element is removed from the DOM
  useLayoutEffect(() => {
    try {
      if (
        typeof document !== 'undefined' &&
        document.activeElement?.tagName === 'BODY' &&
        ref.current?.portal?.content
      ) {
        ref.current.portal.content.focus();
      }
    } catch (e) {}
  });

  const isTest = process.env.NODE_ENV === 'test';
  let overlay_top;
  try {
    overlay_top = document.getElementById('WIX_ADS')?.offsetHeight || 0;
  } catch (e) {
    overlay_top = 0;
  }

  const sideContentDirectionClass = isSideContent ? `sideContent${isRTL ? 'Rtl' : 'Ltr'}` : '';
  const styleForDropdowns = isMobile ? {} : { overflow: 'visible' };
  return (
    <ReactModal
      {...props}
      preventScroll
      className={{
        base: `${styles.modal} ${isSideContent && styles.sideContent} ${styles[sideContentDirectionClass]} ${
          props.compClassName
        }`,
        afterOpen:
          wasOpen || isTest ? classNames([styles.modalAfterOpen, props.hasInnerScroll && styles.hasInnerScroll]) : '',
        beforeClose: styles.modalBeforeClose,
      }}
      overlayClassName={{
        base: `${styles.backgroundOverlay} ${isSideContent && styles.sideContent} ${
          styles[sideContentDirectionClass]
        } restaurants-olo-client-modal-overlay`,
        afterOpen: wasOpen || isTest ? styles.backgroundOverlayAfterOpen : '',
        beforeClose: styles.backgroundOverlayBeforeClose,
      }}
      style={{ overlay: { top: `${overlay_top}px` }, content: { top: `${overlay_top}px`, ...styleForDropdowns } }}
      parentSelector={parentSelector}
      closeTimeoutMS={isTest ? 0 : parseInt(styles.transitionDuration, 10)}
      ref={ref}
      onAfterOpen={isTest ? undefined : afterOpenModal}
      onAfterClose={isTest ? undefined : afterClose}
    >
      {props.children}
    </ReactModal>
  );
};

Modal.setAppElementSelectors = (modalSelector, mainSelector, isEditorX) => {
  if (!isEditorX) {
    parentSelector = () => document.querySelector(modalSelector) ?? document.body;
  }
  ReactModal.setAppElement(mainSelector);
};

export default Modal;
